@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: "Poppins", sans-serif;
}
html {
  background: #1C1F1F;
  color: black;
  line-height: 1.5;
  tab-size: 4;
}
body {
  font-family: inherit;
}
html,
body,
#__next {
  min-height: 100%;
  margin: 0px;
  background-repeat: no-repeat;
}
#root {
  overflow: hidden;
}
:root {
  --app-height: 100%;
}
.checkout, .pioneer {
  height: 100vh;
  height: var(--app-height);
}
.pioneer::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
@media only screen and (min-width: 993px) {
  body,
  html {
    max-width: 100%;
  }
}
audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}
blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  margin-block-start: 0;
  -webkit-margin-after: 1em;
  margin-block-end: 0;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
  -webkit-padding-start: 28px;
  padding-inline-start: 28px;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button,
select {
  text-transform: none;
}
button {
  overflow: visible;
}
button:disabled {
  /* opacity: 0.5; */
  cursor: not-allowed !important;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
img {
  border: 0;
}
img,
video {
  max-width: 100%;
  height: auto;
}
input {
  line-height: normal;
  background: transparent;
  border: none;
  outline: none;
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #333 inset !important;
    -webkit-text-fill-color: #FFF !important;
    caret-color: white;
}
a {
  outline: none;
}
input::placeholder {
  color: #888888;
}
*,
::before,
::after {
  box-sizing: border-box;
}
h1,
h2 {
  line-height: 110%;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
.roadmap-ul >li {
  font-family: Rubik, sans-serif;
}